:root {
  --white: #fff;
  --primary: #e39724;
  --secondary: #545454;
  --secondary-2: #e2dfdf;
  --secondary-light: #f8f8f8;

  --swiper-pagination-color: var(--primary);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 12px;
  --swiper-pagination-bottom: 12px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;
  --swiper-pagination-bullet-inactive-color: var(--secondary);
  --swiper-pagination-bullet-inactive-opacity: 0.15;
  --swiper-pagination-bullet-opacity: 1;
}
