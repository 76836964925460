@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "styles/_variables";

html,
body {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: var(--secondary);
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--white);
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

input {
  outline: 0;
}

.swiper {
  width: 100%;
  padding-bottom: 50px;
}

.swiper-pagination-bullet {
  transition: all 0.3s;
}

.swiper-pagination-bullet-active {
  width: 30px;
  border-radius: 30px;
}

.vertical-grid {
  background-size: 50px 100%;
  background-position: left;
  background-image: linear-gradient(to left,
      rgba(0, 0, 0, 0.03) 0%,
      transparent 5%,
      transparent 5%);
}

.horizontal-grid {
  background-size: 100% 50px;
  background-position: top;
  background-image: linear-gradient(to top,
      rgba(0, 0, 0, 0.03) 0%,
      transparent 5%,
      transparent 5%);
}

*::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--secondary-2);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);

}

.error-row {
  background-color: #e66f6f66 !important;
}

.filter-button {
  float: right;
  margin-bottom: -2.2rem;
}